import React from "react";
import { Navigation } from "../component";
import { Footer } from "../component";
import HomeSlider from "../component/HomeSlider";
import paymentInformation from "../images/payment-information-bro.png";
import idBadge from "../images/id-badge.png";
import checkCircle from "../images/check-circle.png";
import handshake from "../images/handshake.png";
import solutionBusiness from "../images/solution-business.png";
import solutionIndividual from "../images/solution-individual.png";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="home">
      <header>
        <Navigation />
      </header>
      <HomeSlider />

      <section className="home-benefit">
        <div className="container">
          <div className="col-12">
            <div className="section-title">
              <span>Our Concept</span>
              <h2>
                <strong>Benefits</strong> we provide
              </h2>
            </div>
          </div>
          <div className="benefit-area">
            <div className="row">
              <div className="col-4">
                <div className="benefit-box box-1">
                  <img src={idBadge} alt="Mobile ID" />
                  <h4>Mobile ID</h4>
                  <p>
                    1:ID is a SaaS solution for automated identification of
                    private individuals.
                  </p>
                </div>
                <div className="benefit-box box-2">
                  <img src={checkCircle} alt="Secure Data Sharing" />
                  <h4>Secure Data Sharing</h4>
                  <p>
                    1:ID observes EU legislation and are in compliance with GDPR & eIDAS. AWS KMS applied for high security data protection - Multiple algorithms used for encryption of data (AWS KMS &  AES-256-CBC).
                  </p>
                </div>
              </div>
              <div className="col-4">
                <div className="benefit-img">
                  <img src={paymentInformation} alt="Payment information" />
                </div>
              </div>
              <div className="col-4">
                <div className="benefit-box box-3">
                  <img src={handshake} alt="Onboarding Solution" />
                  <h4>Onboarding Solution</h4>
                  <p>
                    By a simple command private users can share their personal
                    data with selected 1:ID partners, while keeping their data
                    safe and remaining in control of permissions granted and
                    reverted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-solution">
        <div className="container">
          <div className="col-12">
            <div className="section-title">
              <span>SOLUTIONS</span>
              <h2>
                <strong>Solutions</strong> we provide
              </h2>
            </div>
          </div>

          <div className="solution-area">
            <div className="row">
              <div className="col-6">
                <div className="solution-box">
                  <div className="solution-image">
                    <img src={solutionBusiness} alt="Business" />
                  </div>
                  <div className="solution-content">
                    <h3>Business</h3>
                    <p>
                      1:ID guarantees automated secure digital identification of
                      your customers in the most lean and cost-effective manner
                      ever invented.
                    </p>
                    <Link to="/business">I Run a Business</Link>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="solution-box">
                  <div className="solution-image">
                    <img src={solutionIndividual} alt="Individual" />
                  </div>
                  <div className="solution-content">
                    <h3>Private</h3>
                    <p>
                      Get FREE secure digital ID on your mobile phone. Available
                      for ALL nationalities worldwide with ePassport.
                    </p>
                    <Link to="/individual">I AM AN INDIVIDUAL</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-vision">
        <div className="container">
          <div className="vision-content">
            <h6>OUR VISION</h6>
            <h2>
              To install 1<em>:</em>ID with more than <span>10 million</span> EU
              citizens
              <span> before 2027</span>.
            </h2>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
