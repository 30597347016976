import React from 'react'
import Footer  from '../component/Footer';
import Navbar from '../component/Navbar';
import facebookimg from '../images/new_assets/ic_facebook.png'
import twitterimg from '../images/new_assets/ic_twitter.png'
import linkedin from '../images/new_assets/ic_linkedin.png'
import download from '../images/new_assets/ic_download.png';
import facebookpng from '../images/new_assets/facebook_logo.png';
import facebookjpg from '../images/new_assets/facebook_logo.jpg';
import facebookpdf from '../images/new_assets/facebook_logo.pdf';
import oneidlogopng from '../images/new_assets/oneid_logo.png';
import oneidlogojpg from '../images/new_assets/oneid_logo.jpg';
import oneidlogopdf from '../images/new_assets/oneid_logo.pdf';

const MainCard=(props)=>{
    return(
        <>
          <div className="col-12 col-md-4">
                    <div className='logobox'>
                        <h3>{props.Socialmedianame}</h3>
                        <img className='sociamedia_img' src={props.Socialmediaimg} alt="" />
                        <p className='d-flex'><a href={props.png} className='imgdownload' download={props.downloadName}><img src={download} className='downloaicon' alt=""/><p><span className='pngone'>PNG</span><br></br><span className='sizeone'>{props.pngsize}</span></p> </a></p>
                        <p className='d-flex'><a href={props.jpg} className='imgdownload' download={props.downloadName}><img src={download} className='downloaicon' alt=""/><p><span className='pngone'>JPG</span><br></br><span className='sizeone'>{props.jpgsize}</span></p> </a></p>
                        <p className='d-flex'><a href={props.pdf} className='imgdownload' download={props.downloadName}><img src={download} className='downloaicon' alt=""/><p><span className='pngone'>PDF</span><br></br><span className='sizeone'>{props.pdfsize}</span></p> </a></p>
                    </div>
                </div>
        </>
    )
}

function DesignManual() {
  return (
    <div>
        <Navbar/>
        <section className='solution-banner'>
        <div className="section-title"><span>Design Manual</span></div>
        <h1>Choose what suits best for your <span>Website</span></h1>
        </section>
        <section className='social_page'>
            <div className="container">
            <div className="section-title section_heading">
                <span>LOGOS</span>
                <h2><strong>Logos</strong>  that define you</h2></div>
            <div className="row g-4">
                <MainCard Socialmedianame={'Facebook'} Socialmediaimg={facebookimg} png={facebookpng} jpg={facebookjpg} pdf={facebookpdf} downloadName={'1id_Facebook'} pngsize={'121 KB'} jpgsize={'71 KB'} pdfsize={'3 KB'} />
                <MainCard Socialmedianame={'Twitter'} Socialmediaimg={twitterimg} png={oneidlogopng} jpg={oneidlogojpg} pdf={oneidlogopdf} downloadName={'1id_Twitter'} pngsize={'8 KB'}  jpgsize={'12 KB'} pdfsize={'3 KB'}/>
                <MainCard Socialmedianame={'Linkedin'} Socialmediaimg={linkedin} png={oneidlogopng} jpg={oneidlogojpg} pdf={oneidlogopdf} downloadName={'1id_Linkedin'} pngsize={'8 KB'} jpgsize={'12 KB'} pdfsize={'3 KB'}/>
            </div>
            </div>
        </section>
        <Footer/>
    </div>
  )
}

export default DesignManual