import React from "react";
import io from "socket.io-client";
import config from "./config";

const ENDPOINT = (config.APP_ENV === "local") ? config.NodeAPIURL_local : config.NodeAPIURL;

export const socket = io.connect(ENDPOINT, {
  cors: {
    // origin: "*",
    origin: ["http://localhost:3000", "https://www.1id.one", "https://1id.one", "https://dev.1id.one", "https://www.dev.1id.one"],
    methods: ["GET", "POST"],
  },
  secure: true,
  transports: ["websocket"],
});

console.log("socket", socket);

socket.on("connect", function () {
  console.log("socket-->>>>", socket);
  const sessionID = socket.id; //
  localStorage.setItem("deviceID", sessionID);
});

socket.on("connect_error", (err) => {
  console.log(`connect_error due to ${err}`);
  setTimeout(() => {
    socket.connect();
  }, 1000);
});

export const SocketContext = React.createContext();
