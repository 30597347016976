import React, { useState } from "react";
import Slider from "react-slick";
import homeSlide1 from "../images/slider/home_slider_1.png";
import homeSlide2 from "../images/slider/home_slider_2.png";
import homeSlide2_Mob from "../images/slider/home_slider_2_mob.png";
import homeSlide3 from "../images/slider/home_slider_3.png";
import homeSlide4 from "../images/slider/home_slider_4.png";
import homeSlide5 from "../images/slider/home_slider_5.png";
import homeSlide6 from "../images/slider/home_slider_6.png";
import homeSlide7 from "../images/slider/home_slider_7.png";
import homeSlide8 from "../images/slider/home_slider_8.png";
import homeSlide8_Mob from "../images/slider/home_slider_8_mob.png";

import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";

const HomeSlider = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // const scrollToBottom = () => {
  //   toggle();

  //   setTimeout(function () {
  //     var scrollDiv = document.getElementById("footer").offsetTop;
  //     window.scrollTo({
  //       top: scrollDiv,
  //       behavior: "auto",
  //     });
  //   }, 100);
  // };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
  };
  return (
    <section className="home-slider">
      <Slider {...settings}>
        <div className="home-slider-item slide1">
          <div className="container">
            <div className="identify-people">
              <div className="row">
                <div className="col-sm-6">
                  <div className="freepik-image">
                    <img
                      className="img-desk"
                      src={homeSlide1}
                      alt="We identify people"
                    />
                    <img
                      className="img-mob"
                      src={homeSlide1}
                      alt="We identify people"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="identify-people-text-block">
                    <h2>
                      <span className="text-color">We</span> Identify People
                    </h2>
                    <div className="we-digitize">
                      <p>
                        We digitize and manage IDs for private individuals and
                        service businesses in demand for customer
                        identification.
                      </p>
                      <p>
                        Share your personal data with partners and other users
                        directly from the 1:ID app by a simple command.
                      </p>
                    </div>
                    <div>
                      <Button className="join-now-btn" onClick={toggle}>
                        Join Now
                      </Button>
                      <Modal
                        isOpen={modal}
                        toggle={toggle}
                        className="join-modal"
                      >
                        <ModalHeader toggle={toggle}>Join Now</ModalHeader>
                        <ModalBody>
                          <div className="join-wrapper">
                            <Link
                              to="/individual"
                              className="join-private join-pink"
                            >
                              For Private
                            </Link>
                            <Link
                              to="/business"
                              className="join-business join-pink"
                            >
                              For Business
                            </Link>
                          </div>
                        </ModalBody>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-slider-item">
          <div className="container">
            <div className="identify-people">
              <div className="row">
                <div className="col-sm-6">
                  <div className="freepik-image">
                    <img
                      className="img-desk"
                      src={homeSlide2}
                      alt="Get free digital ID & certified eSignature on your mobile
                      phone"
                    />
                    <img
                      className="img-mob"
                      src={homeSlide2_Mob}
                      alt="Get free digital ID & certified eSignature on your mobile
                      phone"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="identify-people-text-block">
                    <h1>
                      Get Free Digital ID & Certified eSignature On Your Mobile
                      Phone
                    </h1>
                    <div className="we-digitize">
                      <p>
                        Available for all nationalities worldwide with ePassport
                      </p>
                    </div>
                    <div>
                      <Button className="join-now-btn" onClick={toggle}>
                        Join Now
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-slider-item">
          <div className="container">
            <div className="identify-people">
              <div className="row">
                <div className="col-sm-6">
                  <div className="freepik-image">
                    <img
                      src={homeSlide3}
                      alt="Obtain fast & secure sharing of personal data"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="identify-people-text-block">
                    <h2>Obtain Fast & Secure Sharing Of Personal Data</h2>
                    <div className="we-digitize">
                      <p>
                        Stay in control & track data shared with business
                        partners in real-time
                      </p>
                    </div>
                    <div>
                      <Button className="join-now-btn" onClick={toggle}>
                        Join Now
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-slider-item">
          <div className="container">
            <div className="identify-people">
              <div className="row">
                <div className="col-sm-6">
                  <div className="freepik-image">
                    <img
                      src={homeSlide4}
                      alt="Ask your customers for auto identification"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="identify-people-text-block">
                    <h2>Ask Your Customers For Auto Identification</h2>
                    <div className="we-digitize">
                      <p>
                        Avoid the hazzle of managing scanned passports and
                        driver licenses
                      </p>
                    </div>
                    <div>
                      <Button className="join-now-btn" onClick={toggle}>
                        Join Now
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-slider-item">
          <div className="container">
            <div className="identify-people">
              <div className="row">
                <div className="col-sm-6">
                  <div className="freepik-image">
                    <img
                      src={homeSlide5}
                      alt="Sign documents with eSignature"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="identify-people-text-block">
                    <h2>Sign Documents With eSignature</h2>
                    <div className="we-digitize">
                      <p>
                        Gain trust from a digital signature valid all over the
                        world
                      </p>
                    </div>
                    <div>
                      <a href="https://www.1sign.one" target="_blank" rel="noreferrer"><Button className="join-now-btn">
                        Join Now
                      </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-slider-item">
          <div className="container">
            <div className="identify-people">
              <div className="row">
                <div className="col-sm-6">
                  <div className="freepik-image">
                    <img
                      src={homeSlide6}
                      alt="Observe EU GDPR & eIDAS regulations"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="identify-people-text-block">
                    <h2>Observe EU GDPR & eIDAS Regulations</h2>
                    <div className="we-digitize">
                      <p>
                        Best-in-class encryption technology integrated and data
                        stored safely with AWS, Ireland
                      </p>
                    </div>
                    <div>
                      <Button className="join-now-btn" onClick={toggle}>
                        Join Now
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-slider-item">
          <div className="container">
            <div className="identify-people">
              <div className="row">
                <div className="col-sm-6">
                  <div className="freepik-image">
                    <img src={homeSlide7} alt="Stay KYC compliant" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="identify-people-text-block">
                    <h2>Stay KYC Compliant</h2>
                    <div className="we-digitize">
                      <p>
                        Know your customer and collect IDs in an efficient and
                        secure way
                      </p>
                    </div>
                    <div>
                      <Button className="join-now-btn" onClick={toggle}>
                        Join Now
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===== */}
        <div className="home-slider-item slide1">
          <div className="container">
            <div className="identify-people">
              <div className="row">
                <div className="col-sm-6">
                  <div className="freepik-image">
                    <img
                      className="img-desk"
                      src={homeSlide8}
                      alt="On-Location Identification"
                    />
                    <img
                      className="img-mob"
                      src={homeSlide8_Mob}
                      alt="On-Location Identification"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="identify-people-text-block">
                    <h2>
                      Check ID From Event <br />Visitors On-Location
                    </h2>
                    <div className="we-digitize">
                      <p>
                        Optimize venue security by fast entry scan and true identification of all event attendants
                      </p>
                    </div>
                    <div>
                      <Button className="join-now-btn" onClick={toggle}>
                        Join Now
                      </Button>
                      <Modal
                        isOpen={modal}
                        toggle={toggle}
                        className="join-modal"
                      >
                        <ModalHeader toggle={toggle}>Join Now</ModalHeader>
                        <ModalBody>
                          <div className="join-wrapper">
                            <Link
                              to="/individual"
                              className="join-private join-pink"
                            >
                              For Private
                            </Link>
                            <Link
                              to="/business"
                              className="join-business join-pink"
                            >
                              For Business
                            </Link>
                          </div>
                        </ModalBody>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===== */}

      </Slider>
    </section>
  );
};

export default HomeSlider;
