import React, { useEffect } from "react";
import { Navigation } from "../component";
import { Footer } from "../component";
import identification from "../images/identification.png";
import verification from "../images/verification.png";
import functionality from "../images/functionality.png";
import spaces from "../images/spaces.png";
import growth from "../images/growth.png";
import cost from "../images/cost.png";
import { SiApple } from "react-icons/si";
import { FaGooglePlay } from "react-icons/fa";
import config from "../component/config";

const Individual = () => {
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "auto",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <header>
        <Navigation />
      </header>
      <section className="individual-banner">
        <div className="continer">
          <h1>
            Solutions catered for your <span>Personal</span> need.
          </h1>
        </div>
      </section>
      <div className="personal-plan">
        <div className="container">
          <div className="personal-row">
            <div className="free-box">
              <h4>1:ID</h4>
              <div className="box-download">
                <div className="download-free">
                  <ul>
                    <li>
                      <a
                        href={config.PLAYSTORE}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaGooglePlay className="apple-icon" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={config.APPSTORE}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <SiApple className="apple-icon" />
                      </a>
                    </li>
                  </ul>
                </div>
                <button type="button" onClick={scrollToBottom}>
                  FREE FOREVER
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="business-problem individual-problem">
        <div className="container">
          <div className="col-12">
            <div className="section-title">
              <span>PROBLEMS</span>
              <h2>
                <strong>Problems </strong> that exisits.
              </h2>
            </div>
          </div>
        </div>
        <div className="problem-area">
          <div className="container">
            <div className="row">
              <div className="col-4">
                <div className="problem-box">
                  <div className="problem-image">
                    <img
                      src={identification}
                      alt="Troublesome Identification"
                    />
                  </div>
                  <h4>Troublesome Identification</h4>
                  <p>
                    The demand for personal identification is growing rapidly
                    not only when you are buying services online (travel,
                    banking etc.), BUT also when you are attending events
                    (concerts, sport games) restaurants, bars and clubs, OR
                    purchasing age restricted goods (alcohol, cigarettes,
                    medicine) in supermarkets, drugstores and pharmacies.
                  </p>
                </div>
              </div>
              <div className="col-4">
                <div className="problem-box">
                  <div className="problem-image">
                    <img src={verification} alt="Repeated Verification" />
                  </div>
                  <h4>Repeated Verification</h4>
                  <p>
                    When engaging online you repeatedly have to scan the same
                    copies of personal IDs before purchasing certain services or
                    products. As annoying are requests for photo ID on location
                    when collecting a package or visiting your bank especially
                    when you have brought nothing but your mobile phone.
                  </p>
                </div>
              </div>
              <div className="col-4">
                <div className="problem-box">
                  <div className="problem-image">
                    <img src={functionality} alt="Limited Functionality" />
                  </div>
                  <h4>Limited Functionality</h4>
                  <p>
                    Furthermore, EU national digital identification solutions
                    are limited to functionality in the country where you are a
                    registered citizens preventing the use of digital IDs across
                    EU borders.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="business-opportunity individual-opportunity">
        <div className="container">
          <div className="col-12">
            <div className="section-title">
              <span>Opportunities</span>
              <h2>
                <strong>Opportunities </strong> that follows.
              </h2>
            </div>
          </div>
        </div>
        <div className="problem-area">
          <div className="container">
            <div className="row">
              <div className="col-4">
                <div className="problem-box">
                  <div className="problem-image">
                    <img src={spaces} alt="Secure Spaces" />
                  </div>
                  <h4>Secure Spaces</h4>
                  <p>
                    Situations where digital personal identification is required
                    online will increase severely and over time the urge for
                    tighter security in public spaces will without any doubt
                    escalate.
                  </p>
                </div>
              </div>
              <div className="col-4">
                <div className="problem-box">
                  <div className="problem-image">
                    <img src={growth} alt="Substanable Growth" />
                  </div>
                  <h4>Substanable Growth</h4>
                  <p>
                    Due to GDPR and ePrivacy regulations the demand for keeping
                    track with whom you have shared personal data and where
                    sharing has been reverted will grow substantially.
                  </p>
                </div>
              </div>
              <div className="col-4">
                <div className="problem-box">
                  <div className="problem-image">
                    <img src={cost} alt="Cost Effective" />
                  </div>
                  <h4>Cost Effective</h4>
                  <p>
                    Change in consumer behaviour due to the use of digital
                    payment options results in many relaying only on mobile
                    devices leaving their wallets (and their hard copy IDs) at
                    home. Today a pan European (EU) easy to use cost effective
                    digital ID solution does NOT exist.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="business-value individual-value">
        <div className="container">
          <div className="col-12">
            <div className="section-title">
              <h2>
                Value <strong>Proposition</strong>
              </h2>
            </div>
          </div>
          <div className="value-area">
            <div className="value-box">
              <ul>
                <li>
                  1:ID provides a universal digital ID to ALL EU citizens in
                  possession of an ePassport and mobile phone.
                </li>
                <li>
                  1:ID collects all your IDs into one single app and provides
                  the only FREE secure digital personal ID on your mobile phone
                  in a fast and effective manner to go ready to be provided upon
                  request on location or when shopping online.
                </li>
                <li>
                  By signing up once you avoid the repeated forced request of
                  scanning copies of your passport or drivers license by
                  granting permission to use your personal data simply by adding
                  service providers as partners in the 1:ID app.
                </li>
                <li>
                  By using 1:ID the risk of misuse of your personal ID or loss
                  of original documents is severely reduced.
                </li>
                <li>
                  You can check the businesses with whom you have granted
                  permission to use your personal data and you can easily track
                  the businesses you have asked to delete your data. 1:ID
                  reduces the risk of fraudulent use of sensitive information as
                  users will be able to keep track with the sharing of their
                  personal data.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Individual;
