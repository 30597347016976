import React from 'react'
import { Navigation } from '../component'
import $ from "jquery"
const Developers = () => {
  let url = "https://www.1id.one/";
  $("div#iframe").load(url);
  $("#iframe").css("color","red");
  window.location.href = "https://1id-api-documentation.readme.io/docs";
  return (
    <div className="resources">
      <header>
        <Navigation />
      </header>
      {/* https://1id-api-documentation.readme.io/docs */}
      <div id="iframe">
        
      </div>
    </div>
  );
}

export default Developers