import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { toast } from "react-toastify";
import { Navigation } from "../component";
import { Footer } from "../component";
import { contactUs } from "../services/services";

function Support() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    var form = document.getElementById("support-form");

    console.log(data);
    var formData = new FormData();
    Object.keys(data).forEach((key) => {
      console.log(key, data[key]);
      formData.append(key, data[key]);
    });
    formData.append("is_web", 1);

    let apiResponse = await contactUs(formData);
    if (apiResponse.ResponseCode === 1 || apiResponse.ResponseCode === "1") {
      form.reset();
      toast.success(apiResponse.ResponseMsg);

    }
    else {
      toast.success(apiResponse.ResponseMsg);

    }


  };

  return (
    <div>
      <header>
        <Navigation />
      </header>
      <section className="support-wrapper">
        <div className="container">
          <div className="support-title">
            <h1>
              Help and <strong>Support</strong>
            </h1>
          </div>
        </div>
        <div className="container">
          <div className="form-wrapper">
            <form id="support-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="quote-input">
                <input
                  type="text"
                  placeholder="Name*"
                  {...register("name", {
                    required: "This is required.",
                    maxLength: 80,
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) => (
                    <p className="error-msg">{message}</p>
                  )}
                />
              </div>

              <div className="quote-input">
                <input
                  type="email"
                  placeholder="Email*"
                  {...register("email", {
                    required: "This is required.",
                    pattern: /^\S+@\S+$/i,
                    message: "error message",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => (
                    <p className="error-msg">{message}</p>
                  )}
                />
              </div>

              <div className="quote-input">
                <input
                  type="text"
                  placeholder="Subject*"
                  {...register("subject", {
                    required: "This is required.",
                    maxLength: 100,
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="subject"
                  render={({ message }) => (
                    <p className="error-msg">{message}</p>
                  )}
                />
              </div>

              <div className="quote-input">
                <textarea
                  type="text"
                  placeholder="Message*"
                  {...register("message", {
                    required: "This is required.",
                    maxLength: 500,
                  })}
                ></textarea>
                <ErrorMessage
                  errors={errors}
                  name="message"
                  render={({ message }) => (
                    <p className="error-msg">{message}</p>
                  )}
                />
              </div>

              <div className="quote-submit">
                <input type="submit" value="Send" />
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Support;
