import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import { Home, About, Individual, Business, Support, DesignManual } from "./view";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SocketContext, socket } from "../src/component/socket";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Developers from "./view/Developers";
import CheckMyId from "./view/CheckMyId";

const App = () => {
  return (
    <Router basename="/">
      <Switch>
        <SocketContext.Provider value={socket}>
          <Route path="/" component={Home} exact={true}></Route>
          <Route path="/login" component={Home} exact={true}></Route>
          <Route path="/about" component={About} exact={true}></Route>
          <Route path="/business" component={Business} exact={true}></Route>
          <Route path="/design-manual" component={DesignManual} exact={true}></Route>
          <Route path="/individual" component={Individual} exact={true}></Route>
          <Route path="/developers" component={Developers} exact={true}></Route>
          <Route path="/support" component={Support} exact={true}></Route>
          <Route path="/:email([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}[0-9]*)" component={CheckMyId} exact={true}></Route>
        </SocketContext.Provider>
      </Switch>
      <ToastContainer position="top-right" autoClose={5000} />
    </Router>
  );
};

export default App;
