import React, { useState, useEffect, useContext } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { NavLink as RRNavLink } from "react-router-dom";
import logo from "../images/1_ID.png";
import { Link } from "react-router-dom";
import qrmobile from "../images/code-mobile.png";
import QRCode from "react-qr-code";
import checkiconDark from "../images/check-icon-dark.png";
import success from "../images/success.png";
import backArrow from "../images/back-arrow.png";
import { SocketContext } from "../component/socket";
import config from "../component/config";
import { getCompanyName } from "../services/services";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [accountModal, setAccountModal] = useState(false);

  const socket = useContext(SocketContext);
  const [scompany, setScompany] = useState(true);
  const [isAdmin, setAdminId] = useState(0);
  const [compList, setCompList] = useState();
  const [company, setCompany] = useState();
  const [selectCompanyId, setCompanyId] = useState();
  const [respmsg, setRespmsg] = useState();
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [loginType, setLoginType] = useState();
  // const [termsTrue, setTermsTrue] = useState(true);
  // const [privacyTrue, setPrivacyTrue] = useState(true);
  // const [privacyTerms, setPrivacyTerms] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [device_id, setDeviceId] = useState(localStorage.getItem("deviceID"));

  const messagesuc = () => {
    setMessageSuccess(!messageSuccess);
  };

  const messagesucClose = () => {
    setMessageSuccess(!messageSuccess);
    // console.log(loginType);
    if (loginType == "KYCLogin" || loginType == "selectCompany") {
      messagesuc(false);
    } else if (loginType == "KYCLoginSuccess") {
      messagesuc(false);

      // window.location = config.baseURL + `Partner/Login/KycLogin?companyName=${company}&cId=${selectCompanyId}`;
    }
  };
  const [userId, setUserId] = useState();

  const account = () => setAccountModal(!accountModal);
  const accountClose = () => {
    setAccountModal(!accountModal);
    setScompany(true);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      document.body.classList.remove("menuOpen");
    } else {
      document.body.classList.add("menuOpen");
    }
  };

  useEffect(() => {

    const pathName = window.location.pathname;
    if (pathName == "/login") {
      account();
    }

    document.body.classList.remove("menuOpen");

    socket.on("connect", function () {
      setDeviceId(socket.id);
      // console.log("socketnavbar-->>>>", socket.id);
    });

    socket.on("message", (data) => {
      // console.log("socket react --->>>", data);
      if (data && data.ResponseCode && data.ResponseCode == 1) {
        // console.log(data.data.msg);
        // console.log(data.data.type);
        if (data.data.device_id == localStorage.getItem("deviceID")) {
          if (data.data.type == "selectCompany") {
            setCompList(data.data.companyList);
            setCompany(data.data.companyList[0].company_name);
            setCompanyId(data.data.companyList[0].company_id);
            setScompany(false);
            setUserId(data.data.user_id);
            setLoginType(data.data.type);
          } else if (data.data.type == "KYCLogin") {
            setRespmsg(data.data.msg);
            setAccountModal(false);
            // messagesuc(true);
            setLoading(true);
            setLoginType(data.data.type);
            // company name set aaya karavanu
            setCompany(data.data.company_name);
            setCompanyId(data.data.company_id);
            setAdminId(data.data.is_admin);
            // console.log('is_admin------>>>>>kycLogin>>>>>', data.data.is_admin)
          } else if (data.data.type == "KYCLoginSuccess") {
            setRespmsg(data.data.msg);
            setAccountModal(false);
            // messagesuc(true);
            setLoginType(data.data.type);
            // company name set aaya karavanu
            setCompany(data.data.company_name);
            setCompanyId(data.data.company_id);
            setAdminId(data.data.is_admin);
            // console.log('is_admin------>>>>>>>>>>', data.data.is_admin)
            var loginURL = config.baseURL +
              `Partner/Login/KycLogin?companyName=${data.data.company_name}&cId=${data.data.company_id}&is_operator=${data.data.is_operator}&operator_id=${data.data.operator_id}`;;
            if (data.data.is_admin && (data.data.is_admin == 1 || data.data.is_admin == "1")) {
              loginURL =
                config.baseURL +
                `Admin/Login/controllerLogin?cId=${data.data.company_id}&is_controller=1&nationality=${data.data.allow_nationlity}`;
            }

            window.location = loginURL;
            setLoading(false);
          }
        }
      }
      else if (data && data.ResponseCode && data.ResponseCode == 2) {
        let resMsg = data.data.msg ?? "";
        setRespmsg(resMsg);
        setLoading(false);
      }
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnecting");
    });
  }, []);

  const selectCompany = async () => {
    // console.log(company);

    var formData = new FormData();
    formData.append("user_id", userId);
    formData.append("company_name", company);
    formData.append("company_id", selectCompanyId);
    formData.append("is_admin", isAdmin);
    formData.append("device_id", localStorage.getItem("deviceID"));

    let apiResponse = await getCompanyName(formData);
    if (apiResponse.ResponseCode === 1 || apiResponse.ResponseCode === "1") {
      setRespmsg(apiResponse.ResponseMsg);
      setAccountModal(false);
      setMessageSuccess(true);
      // company name set aaya karavanu
      setCompany(apiResponse.company_name);
      setCompanyId(apiResponse.company_id);
      setScompany(true);
      setLoading(true);

    } else {
      setLoading(false);
    }

  };

  // useEffect(() => {
  //   if (privacyTrue == true && termsTrue == true) {
  //     setPrivacyTerms(true);
  //   } else {
  //     setPrivacyTerms(false);
  //   }
  // }, [privacyTrue]);

  // useEffect(() => {
  //   if (privacyTrue == true && termsTrue == true) {
  //     setPrivacyTerms(true);
  //   } else {
  //     setPrivacyTerms(false);
  //   }
  // }, [termsTrue]);

  return (
    <div>
      {isLoading && (
        <div className="loader-bg">
          <h3>Loading...</h3>
          <div className="loader">
            <span></span>
          </div>
        </div>
      )}
      <Navbar light expand="md" className="bg-navigation">
        <div className="container">
          <NavbarBrand>
            <Link to="/">
              <img src={logo} alt="1ID" />
            </Link>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="rightNav ml-auto" navbar>
              <NavItem>
                <NavLink to="/" activeClassName="active" exact tag={RRNavLink}>
                  <NavbarText>Home</NavbarText>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/about"
                  activeClassName="active"
                  exact
                  tag={RRNavLink}
                >
                  <NavbarText>About</NavbarText>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/business"
                  activeClassName="active"
                  exact
                  tag={RRNavLink}
                >
                  <NavbarText>For Business</NavbarText>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/individual"
                  activeClassName="active"
                  exact
                  tag={RRNavLink}
                >
                  <NavbarText>For Private</NavbarText>
                </NavLink>
              </NavItem>
              <li className="drop-down">
                <a href="#">Resources</a>
                <ul className="dropdown">
                  <li>
                    <a href="/developers" target="_blank">Documentation</a>
                  </li>
                  <li>
                    <NavLink className="design-manual" tag={RRNavLink} to="/design-manual">Design Manual</NavLink>
                  </li>
                </ul>
              </li>

              <NavItem>
                <button type="button" onClick={account}>
                  Login
                </button>
              </NavItem>
            </Nav>
          </Collapse>
        </div>
      </Navbar>

      <Modal
        isOpen={accountModal}
        toggle={accountClose}
        className="generate-modal"
      >
        <ModalHeader>
          <img src={logo} alt="1ID" />
          <span className="ac-mid-title">Login</span>
          <span className="ac-sub-title">1:PARTNERS</span>
        </ModalHeader>
        <ModalBody>
          {scompany ? (
            <div className="generate-div">
              <h5>Identify & Login</h5>
              <p>
                Identify yourself and login by scanning QR code using scan
                function located at the bottom navigation of the 1ID App.
              </p>
              <div className="qr-div">
                <img src={qrmobile} alt="" />
                <QRCode size="200" value={`KYCLogin;${device_id}`} />
                {/* <img src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=KYCLogin;${device_id}&choe=UTF-8`} alt="" /> */}
              </div>
              <div className="generate-footer">
                <button
                  type="button"
                  onClick={account}
                  className="margin_0auto"
                >
                  Cancel
                </button>

              </div>
            </div>
          ) : (
            <div className="account-select">
              <h5>Select Account</h5>
              <p>There are several accounts associated with your name.</p>
              <p>Select account for login.</p>
              <div className="select-company companylist">
                <div className="row">
                  {compList &&
                    compList.map((cData, index) => (
                      <div className="col-sm-6 col-12" key={index}>
                        <label
                          htmlFor={cData.company_id}
                          onClick={() => { setCompany(cData.company_name); setCompanyId(cData.company_id); setAdminId(cData.is_admin); }}
                        >
                          <input
                            type="radio"
                            name="select_company"
                            id={cData.company_id}
                            value={cData.company_name}
                            // checked={selectCompanyId == cData.company_id}
                            onChange={() => { setCompany(cData.company_name); setCompanyId(cData.company_id); setAdminId(cData.is_admin); }}
                          />
                          <button type="button">
                            <span>
                              <div className="displaygird">
                                <p className="displaygird-text">
                                  {cData.company_name}
                                </p>
                                <p>{cData.registration_number}</p>
                                <p>({cData.country})</p>
                              </div>
                              <div className="displaygird">
                                <img src={checkiconDark} alt="" />
                              </div>
                            </span>
                          </button>
                        </label>
                      </div>
                    ))}
                </div>
              </div>
              <div className="account-footer">
                <button
                  type="button"
                  className="btn-dark-border"
                  onClick={accountClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-darken"
                  onClick={selectCompany}
                >
                  OK
                </button>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={messageSuccess}
        toggle={messagesuc}
        className="formmsg-modal"
      >
        <ModalBody>
          <button
            type="button"
            className="back-arrow"
            onClick={messagesucClose}
          >
            <img className="scs-img" src={backArrow} alt="" />
          </button>
          <div className="form-message-body">
            <img className="scs-img" src={success} alt="" />
            <h4>Success!</h4>
            <p>{respmsg}</p>
          </div>
          <div className="account-footer">
            {/* <button type="button" className="btn-darken" onClick={messagesucClose}>
              OK
            </button> */}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Navigation;
