import axios from "axios";
import config from "../component/config";
const options = {
  key: "2b223e5cee713615ha54ac203b24e9a123703143mk",
  "Content-Type": "application/json; charset=utf-8",
  "Access-Control-Allow-Origin": "*",
};

const APIBASE_URL = (config.APP_ENV === "local") ? config.apiUrl_local : config.apiUrl;
const NODEBASE_URL = (config.APP_ENV === "local") ? config.NodeAPIURL_local : config.NodeAPIURL;

// createTenant
const createSubscription = async (data) => {
  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${NODEBASE_URL}/CreateSubscription`, data)
      .then(async (response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
  return await apiPromise;
};


// createTenant
const updateAccountInfo = async (data, tenantCode) => {
  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${NODEBASE_URL}/accountUpdate/${tenantCode}`, data)
      .then(async (response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
  return await apiPromise;
};

// getQuote
const getQuote = async (data) => {
  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${APIBASE_URL}/getQuote`, data, {
        headers: options,
      })
      .then(async (response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
  return await apiPromise;
};

// getCompanyName
const getCompanyName = async (data) => {
  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${APIBASE_URL}/getCompanyName`, data, {
        headers: options,
      })
      .then(async (response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
  return await apiPromise;
};

// checkCompany
const checkCompany = async (data) => {
  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${APIBASE_URL}/checkCompany`, data, {
        headers: options,
      })
      .then(async (response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
  return await apiPromise;
};

// contactUs
const contactUs = async (data) => {
  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${APIBASE_URL}/contactUs`, data, {
        headers: options,
      })
      .then(async (response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
  return await apiPromise;
};


// getUserByEmail
const getUserByEmail = async (data) => {
  var apiPromise = new Promise((resolve, reject) => {
    axios
      .post(`${APIBASE_URL}/getUserByEmail`, data, {
        headers: options,
      })
      .then(async (response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
  return await apiPromise;
};


export { createSubscription, updateAccountInfo, getQuote, getCompanyName, checkCompany, contactUs, getUserByEmail }