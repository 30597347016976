import React, { useContext, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import qrmobile from "../images/code-mobile.png";
import { SocketContext } from "../component/socket";
import config from "../component/config";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import logo from "../images/1_ID.png";
import IOSIcon from "../images/download_iOS.png";
import AndroidIcon from "../images/download_android.png";
import { toast } from "react-toastify";
import { getUserByEmail } from "../services/services";

function getParamFromQueryString(queryString, paramName) {
    const params = queryString.slice(1).split('&');
    for (let i = 0; i < params.length; i++) {
        const [key, value] = params[i].split('=');
        if (key === paramName) {
            return decodeURIComponent(value);
        }
    }
    return null;
}

const CheckMyId = (props) => {
    const [device_id, setDeviceId] = useState(localStorage.getItem("deviceID"));
    const socket = useContext(SocketContext);
    const [isOpenModel, setOpenModel] = useState(false);

    const [uParam] = useState(props.match.params.email);

    useEffect(async () => {
        var formData = new FormData();
        formData.append("email", uParam);
        let apiResponse = await getUserByEmail(formData);

        if (apiResponse.ResponseCode === 1 || apiResponse.ResponseCode === "1") {
            setuserData(apiResponse.data);
            setOpenModel(true);
        }
        else {
            toast.error(apiResponse.ResponseMsg);
            window.location.href = config.Website;

        }
    }, []);
    const [userData, setuserData] = useState({});

    socket.on("connect", function () {
        setDeviceId(socket.id);
    });

    socket.on("message", async (data) => {
        console.log("scan response--->>>", data);
        if (data && data.ResponseCode && data.ResponseCode == 1) {
            if (data.data.device_id == localStorage.getItem("deviceID")) {
                toast.success(data.ResponseMsg);
                if (data.data.type == "check_my_id") {
                    closeModal();
                }
            }
        }
        else {
            toast.error(data.ResponseMsg);
        }
    });


    const closeModal = () => {
        window.location.href = ((config.APP_ENV == "live") ? config.Website : config.Website_local);
    };

    return (
        <div>
            <Modal
                isOpen={isOpenModel}
                toggle={closeModal}
                className="generate-modal"
            >
                <ModalHeader>
                    <img src={logo} alt="1ID" />
                    <span className="ac-mid-title">{userData ? (userData).name + ' ' + (userData).surname : ""}</span>
                    <span className="ac-sub-title">1:CHECK ID</span>
                </ModalHeader>
                <ModalBody>
                    <div className="generate-div">
                        <h5>Identify & Check-My-ID</h5>
                        <p>
                            You can check the ID of {userData ? (userData).name + ' ' + (userData).surname : ""} by reading the QR code using the scan function located at the
                            bottom navigation of your 1ID App.
                        </p>
                        <p>
                            If you are not an 1ID user already, please download the App "1ID-My Digital Profile" from the Apple App Store /
                            Google Play Store.
                        </p>
                        <div className="qr-div">
                            <img src={qrmobile} alt="" />
                            <QRCode size="200" value={`checkMyId;${device_id};${btoa(JSON.stringify(userData))}`} />
                        </div>
                        <div className="generate-footer">
                            <button
                                type="button"
                                onClick={closeModal}
                                className="margin_0auto"
                            >
                                Cancel
                            </button>
                        </div>
                        <div className="download-free justify-center">
                            <ul className="store_icons">
                                <li>
                                    <a
                                        href={config.APPSTORE}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={IOSIcon} width="150px" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href={config.PLAYSTORE}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={AndroidIcon} width="150px" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default CheckMyId;
