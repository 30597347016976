import React from "react";
import { Navigation } from "../component";
import ID from "../images/1ID_card.png";
import voidStemp from "../images/void_stamp.png";
import { Footer } from "../component";

const About = () => {
  return (
    <div>
      <header>
        <Navigation />
      </header>
      <section className="header">
        <div className="container">
          <div className="about-header-box">
            <div>
              <h1>
                <span className="text-color">We </span>Identity People
              </h1>
            </div>
            <div className="about-we-digitize">
              <p className="about-content-p">
                We digitize and manage IDs for private individuals and service
                businesses in demand for customer identification. We offer
                individuals to share personal data with partners and other users
                directly from the 1:ID app by a simple command.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="kenneth-tang-box">
            <div className="row">
              <div className="col-auto">
                <img src={ID} alt="" />
              </div>
              <div className="col-auto">
                <div className="kenneth-tang-laerke ">Kenneth Tang Laerke</div>
                <div className="founder-and-ceo">CEO</div>
                <div className="the-company-block about-content-p">
                  {/* The company was founded by
                  <span className="bold-500"> William Tang Laerke </span>
                  and <span className="bold-500">Kenneth Tang Laerke</span>.
                  Kenneth has recently been appointed CEO as William is engaged
                  in the design of the new document signing project 1:SIGN where
                  the eSignature is delivered by 1:ID. */}
                  <span className="bold-500">LARK Invest ApS</span> is the
                  founder of 1ID and also the owner of sister company
                  <span className="bold-500"> 1SIGN ApS</span>.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="disclaimer-block">
            <div className="text-center">
              <h2>Disclaimer</h2>
            </div>
            <div className="disclaimer-box">
              <div className="row">
                <div className="col-auto">
                  <img alt="" src={voidStemp} className="void-stamp-image" />
                </div>
                <div className="col-auto">
                  <div className="void-id">VOID ID</div>
                  <div className="void-id-content">
                    <ul>
                      <li>1:ID is NOT an approved government ID.</li>
                      <li>
                        1:ID is a digital profile to be used for personal
                        identification and sharing of data.
                      </li>
                      <li>
                        When you create a digital profile we read your Passport,
                        scan your Driver License and exhibit designed versions
                        in the app.
                      </li>
                      <li>
                        Be aware that these designs are illustrations ONLY and
                        VOID as government issused IDs and as such NOT validated
                        NOR certified by issuing authorities.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="disclaimer-block mb-100 disclosure-block">
            <div className="text-center">
              <h2>Disclosure</h2>
            </div>

            <div className="disclaimer-box">
              <div className="bold-500 about-content-p">Personal Data Use</div>

              <div className="disclosure-list">
                We process your data to deliver the app and services to you.
                This includes:
              </div>
              <br></br>

              <div className="disclosure-content">
                <ul>
                  <li>Registering and identifying you as a user,</li>
                  <li>
                    Creating your account and setting up your profile in the app
                  </li>
                  <li>giving you access to the 1ID app,</li>
                  <li>Ensuring that you can logintoyour account,</li>
                  <li>
                    Sending you notifications and contacting yu about your 1ID
                    account.
                  </li>
                  <li>
                    Processing your ID and other identification documents,
                  </li>
                  <li>Giving you the ability to have a digital profile.</li>
                  <li>
                    Enabling specific account and product features, log and save
                    the actions you take when you use our app.
                  </li>
                  <li>
                    Responding to your questions and providing you with customer
                    service and support, including sending service-related
                    messages to you and to carry out other services that can be
                    ordered through the app or website.
                  </li>
                </ul>
              </div>
              <br></br>

              <div className="disclosure-list">
                This data we process about you in that regard is:
              </div>
              <br></br>

              <div className="bold-500 about-content-p">
                Ordinary personal data:
              </div>

              <div className="disclosure-list">
                <ul>
                  <li>
                    Your contact details, incl. your name, email, mobile number,
                    address, country, picture,
                  </li>
                  <li>What partners you allow to verify your identity,</li>
                  <li>
                    purchase history, subscription information and payment and
                    billing information, e.g., your credit or debit card details
                    and billing address,
                  </li>
                  <li>
                    Your requests and actions, e.g., sign-up and use of the app,
                    when you accepted our Terms of Service, when you signed up,
                    when you used the app for identification purposees, and when
                    you contacted us for supportetc. If you email us, we will
                    collect the content of your message,
                  </li>
                  <li>
                    Login details and verifications and information about what
                    choices you made when you set up your account, when you
                    became a user, your user role, when you are logging into our
                    product,
                  </li>
                  <li>
                    Other types of interactions you will have with us and our
                    service, e.g. account, app and product setup, user
                    interviews, UX research, customer feedback etc.
                  </li>
                </ul>
                <div className="disclosure-list">
                  Information about how you use our product and services.
                </div>
              </div>
              <br></br>

              <div className="bold-500 about-content-p">
                Sensitive / Confidential information:
              </div>

              <div className="disclosure-list">
                Your passport or driver license or other identificaion document
                that you choose to scan via our app and the information related
                to those IDs, You choose if and what document you want to scan.
                You can at any time delete your scanned document.
              </div>
              <br></br>

              <div className="disclosure-list">
                We ONLY process your sensitive information based on your consent
                / permission, and it is only used for providing the
                functionality of the app.
              </div>
              <div className="disclosure-list">
                YOU CAN WITHDRAW YOUR CONSENT AT ANY TIME.
              </div>
              <br></br>

              <div className="disclosure-list">
                This data is regarded as confidential and regulated as a special
                category of data as we are helping you with making identifiction
                process easier. We limit the collection and processing of this
                type of directly acquired through our app to purposes directly
                related to providing and improving the features of the app.
              </div>
              <br></br>

              <div className="disclosure-list">
                We collect your personal data directly from you and will not
                share it with third parties unless you have given you explicit
                consent.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default About;
