import React from "react";
import { SiApple } from "react-icons/si";
import { FaGooglePlay } from "react-icons/fa";
import qrcode from "../images/qrcode.png";
import config from "./config";

const Footer = () => {
  return (
    <div>
      <section className="footer-bg" id="footer">
        <div className="container">
          <div className="row copy-right-center">
            <div className="col-auto">
              <div className="install-mobile-app">Install mobile app</div>
              <div className="download-one-id">
                <span className="bold-700">
                  Download 1<em>:</em>ID
                </span>
                &nbsp;on your device
              </div>
              <div className="global-mobile">
                The only global mobile ID solution. Verified by{" "}
                <span className="bold-500">ePassport.</span>
              </div>
            </div>
            <div className="col-auto">
              <div className="download-section">
                <div className="download-qrcode">
                  <img src={qrcode} alt="QR Code" />
                  <span>OR</span>
                </div>
                <div className="download-btn-block">
                  <a
                    href={config.APPSTORE}
                    target="_blank"
                    className="download-btn"
                    rel="noreferrer"
                  >
                    <SiApple className="apple-icon" /> Download in App Store
                  </a>
                </div>

                <div className="download-btn-block ">
                  <a
                    href={config.PLAYSTORE}
                    target="_blank"
                    className="download-btn"
                    rel="noreferrer"
                  >
                    <FaGooglePlay className="apple-icon" /> Download in Play
                    Store
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="copy-right-block">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="all-right">&copy; 1:ID. All Rights Reserved.</div>
            </div>
            <div className="col-sm-6">
              <div className="privacy-policy">
                <a
                  className="terms-privacy"
                  href="https://api.1id.one/ONEID/term_condition.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>
                <a
                  className="terms-privacy"
                  href="https://api.1id.one/ONEID/privacy_policy.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
